import React, { useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'

import {
  addChangedFieldIdInReduxByFormName,
  adddChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  checkIsDate,
  cms,
  editCMS,
  removeChangedFieldIdInReduxByFormName,
  tidyName,
  validateForAv,
} from '../../../helper'
import ToggleField from './toggleField'
import { connect, useSelector } from 'react-redux'
import { addChangedFieldIdByFormName } from '../../../Redux/Reducers/CMS'
import moment from 'moment'
import { TagsInput } from 'react-tag-input-component'

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})

const CMSMultipleInput = ({
  data = {},
  toggleActions = [],
  cmsEditMode,
  id = '',
  value = '',
  currentRow,
  onValueChange = () => {},
  formName,
  parentPageName,
  pageName,
}) => {
  const [fieldValue, updateValue] = useState(value)

  useEffect(() => {
    if (parentPageName === 'send_reports' && data?.field_id === 'subject') {
      let defaultValue = ''
      const group_data = currentRow?.[currentRow?.length - 1]

      if (group_data?.location?.name) {
        defaultValue = defaultValue + group_data?.location?.name
      }
      if (group_data?.group_name) {
        defaultValue = defaultValue + ', ' + group_data?.group_name
      }
      if (group_data?.completed_start_date_time) {
        if (checkIsDate(group_data.completed_end_date_time)) {
          let date = new Date(group_data.completed_end_date_time)

          defaultValue = defaultValue + ', ' + moment(date).format('Do MMM, YYYY')
        }
      }
      updateValue(defaultValue)
    } else {
      updateValue(value ? value : '')
    }
  }, [data, value])
  console.log('value:::::::::::::', value, fieldValue)

  const [changedFieldRendered, setChangedFieldRendered] = useState(false)
  const submittedFieldInRedux = useSelector((state) => state.CMS?.submittedChangedField)

  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  useEffect(() => {
    if (currentRow && currentRow?.length > 0 && currentRow[currentRow?.length - 1] && changedFieldRendered) {
      if (fieldValue === value && fieldValue) {
        removeChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
      } else {
        if (fieldValue) {
          addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
        }
      }
    }
    if (!changedFieldRendered) {
      setChangedFieldRendered(true)
    }
  }, [fieldValue])

  let isFieldVisible = true

  if (data?.field_setting?.depend_fields && data?.field_setting?.depend_fields?.length > 0 && currentRow) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      if (currentRow[currentRow?.length - 1]) {
        return checkSingleCondition(
          dependForm?.condition,
          currentRow[currentRow?.length - 1][dependForm?.dependent_field],
          parseInt(dependForm?.value)
        )
      }
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return
  return (
    <>
      <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>
        {tidyName(cms(data.cms_key)) || tidyName(data?.title)}{' '}
        {data.component_call_fields && <ToggleField data={data} toggleActions={toggleActions} />}
      </label>
      <TagsInput
        separators={['Enter', 'Tab']}
        value={fieldValue ? fieldValue : []}
        onChange={(val) => {
          updateValue(val)
          onValueChange(data?.field_id, val)
        }}
        onBlur={(e) => {
          let val = fieldValue || []
          if (e.target.value) {
            val?.push(e.target.value)
            updateValue(val)
            onValueChange(data?.field_id, val)
          }
          e.target.value = ''
        }}
        name={data.field_id || data?.name || 'no_name'}
        placeholder={tidyName(cms(data.cms_key))}
      />
      <AvField
        type={'hidden'}
        name={data.field_id || data?.name || 'no_name'}
        id={id}
        placeholder={tidyName(cms(data.cms_key))}
        value={fieldValue}
        className={isFieldInSync ? 'blue-border' : ''}
        // value={fieldValue}
        // onClick={() => (cmsEditMode ? editCMS(data.cms_key) : null)}
        // onChange={(val) => {
        //   updateValue(e.target.value)
        //   onValueChange(data?.field_id, e.target.value)
        // }}
        validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
        disabled={data.disabled === 1 ? true : false}
      />
    </>
  )
}

export default connect(select)(CMSMultipleInput)
