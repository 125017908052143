import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { clearNotification, setNotification } from '../Redux/Reducers/notificationHandling'

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true)
  const isOnlineRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('online', (event) => {
      setIsOnline(true)
      localStorage.removeItem('IS_INTERNET_ACTIVE')
      dispatch(clearNotification())
    })
    window.addEventListener('offline', (event) => {
      if (isOnlineRef.current && document.visibilityState === 'visible') {
        setIsOnline(false)
        isOnlineRef.current = false
        localStorage.setItem('IS_INTERNET_ACTIVE', 'true')
        dispatch(setNotification({ type: 'error', message: 'Internet disconnected' }))
        setTimeout(() => {
          isOnlineRef.current = true
        }, 8000)
      }
    })
  })

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //         if(localStorage.getItem("IS_INTERNET_ACTIVE")){
  //             fetch('https://www.google.com/', {
  //                 mode: 'no-cors',
  //               })
  //                 .then(() =>{

  //                      setIsOnline(true)
  //                      localStorage.removeItem('IS_INTERNET_ACTIVE')

  //                     })
  //                 .catch(() => {
  //                     dispatch(setNotification({ type: 'error', message: "Internet disconnected" }))
  //                     setIsOnline(false)
  //                 });
  //         }
  //     }, 10000);

  //     return () => clearInterval(interval);
  //   });

  return { isOnline }
}

export default useNetworkStatus
