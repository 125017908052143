import { select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

const defaultConfig = {
  position: 'top-right',
  autoClose: 9000,
}

const displayedMessages = new Set()

export function* showNotification() {
  const { NotificationHandling } = yield select()
  if (displayedMessages.has(NotificationHandling.message)) {
    return
  }
  displayedMessages.add(NotificationHandling.message)
  toast[NotificationHandling.type](NotificationHandling.message, defaultConfig)
  setTimeout(() => {
    displayedMessages.delete(NotificationHandling.message)
    toast.dismiss()
  }, 4000)
}
